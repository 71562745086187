import React, { useState } from 'react'

import {
  AlertCircleIcon,
  ErrorIcon,
  Heading,
  Tab,
  Tabs
} from '@te-digi/styleguide'

import { LinkMock } from '../../components/LinkMock'

const TabsItemStatusExample = () => {
  const [active, setActive] = useState('')

  return (
    <>
      <Heading
        level={1}
        id="heading-id"
      >
        Otsikko (h1) joka kuvaa myös täbien merkityksen
      </Heading>
      <Tabs ariaLabelledby="heading-id">
        <Tab
          active={active === '1'}
          as={LinkMock}
          color="secondary"
          onClick={() => setActive('1')}
          iconRight={<AlertCircleIcon arial-label="Huomioitavaa" />}
        >
          Lorem ipsum
        </Tab>
        <Tab
          active={active === '2'}
          as={LinkMock}
          onClick={() => setActive('2')}
        >
          Dolor sit amet
        </Tab>
        <Tab
          active={active === '3'}
          as={LinkMock}
          color="danger"
          iconRight={<ErrorIcon arial-label="Virheitä" />}
          onClick={() => setActive('3')}
        >
          Consectetuer adipiscing
        </Tab>
        <Tab disabled>Sed posuere</Tab>
      </Tabs>
    </>
  )
}

export { TabsItemStatusExample }
