import React, { useState } from 'react'

import { Heading, Tab, Tabs } from '@te-digi/styleguide'

import { LinkMock } from '../../components/LinkMock'

const TabsSecondaryLongExample = () => {
  const [active, setActive] = useState('')

  return (
    <>
      <Heading
        level={2}
        id="heading-secondary-long-example-id"
      >
        Otsikko (h2) joka kuvaa myös täbien merkityksen
      </Heading>
      <Tabs
        ariaLabelledby="heading-secondary-long-example-id"
        variant={'secondary'}
      >
        <Tab
          active={active === '1'}
          as={LinkMock}
          onClick={() => setActive('1')}
        >
          Lorem ipsum
        </Tab>
        <Tab
          active={active === '2'}
          as={LinkMock}
          onClick={() => setActive('2')}
        >
          Dolor sit amet
        </Tab>
        <Tab
          active={active === '3'}
          as={LinkMock}
          onClick={() => setActive('3')}
        >
          Consectetuer adipiscing
        </Tab>
        <Tab
          active={active === '4'}
          as={LinkMock}
          onClick={() => setActive('4')}
        >
          Mauris varius diam vitae arcu
        </Tab>
        <Tab
          active={active === '5'}
          as={LinkMock}
          onClick={() => setActive('5')}
        >
          Sed arcu lectus auctor vitae
        </Tab>
        <Tab
          active={active === '6'}
          as={LinkMock}
          onClick={() => setActive('6')}
        >
          Consectetuer et venenatis eget velit
        </Tab>
        <Tab
          active={active === '7'}
          as={LinkMock}
          onClick={() => setActive('7')}
        >
          Donec ultricies nisl ut felis
        </Tab>
        <Tab
          active={active === '8'}
          as={LinkMock}
          onClick={() => setActive('8')}
        >
          Aliquam erat volutpat
        </Tab>
        <Tab
          active={active === '9'}
          as={LinkMock}
          onClick={() => setActive('9')}
        >
          Suspendisse facilisis nulla
        </Tab>
      </Tabs>
    </>
  )
}

export { TabsSecondaryLongExample }
