import React, { useState } from 'react'

import { Heading, Tab, Tabs } from '@te-digi/styleguide'

import { LinkMock } from '../../components/LinkMock'

const TabsExample = () => {
  const [active, setActive] = useState('')

  return (
    <>
      <Heading
        level={1}
        id="heading-id"
      >
        Otsikko (h1) joka kuvaa myös täbien merkityksen
      </Heading>
      <Tabs ariaLabelledby="heading-id">
        <Tab
          active={active === '1'}
          as={LinkMock}
          onClick={() => setActive('1')}
        >
          Lorem ipsum
        </Tab>
        <Tab
          active={active === '2'}
          as={LinkMock}
          onClick={() => setActive('2')}
        >
          Dolor sit amet
        </Tab>
        <Tab
          active={active === '3'}
          as={LinkMock}
          onClick={() => setActive('3')}
        >
          Consectetuer adipiscing
        </Tab>
      </Tabs>
    </>
  )
}

export { TabsExample }
