import React from 'react'

import {
  Heading,
  Help,
  List,
  ListItem,
  Paragraph,
  Tab,
  Tabs
} from '@te-digi/styleguide'

import { Link as GatsbyLink } from '../../components/Link'

import { Playground } from '../../components/Playground'
import { Code } from '../../components/Code'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'
import { SectionHeading } from '../../components/SectionHeading'
import { TabsExample } from '../../examples/react/TabsExample'
import { TabsItemStatusExample } from '../../examples/react/TabsItemStatusExample'
import { TabsLongExample } from '../../examples/react/TabsLongExample'
import { TabsSecondaryExample } from '../../examples/react/TabsSecondaryExample'
import { TabsSecondaryLongExample } from '../../examples/react/TabsSecondaryLongExample'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Tabs"
    components={[
      { component: Tabs },
      { component: Tab, restElement: 'component' }
    ]}
  >
    <Section>
      <Paragraph>
        Käytetään jaottelemaan monimutkaisia sisältökokonaisuuksia pienemmiksi
        asiakokonaisuuksiksi.
      </Paragraph>
      <Paragraph>
        React-komponenttina ei suositeltu. Käytä <Code>Tabs</Code>ia vain, jos
        sivun sisällön jaotteluun ei voida teknisistä syistä käyttää{' '}
        <Code>TabList</Code>-komponenttia.
      </Paragraph>
    </Section>

    <Help>
      <Heading
        level={2}
        size={4}
      >
        Tabs ja TabList eroavaisuudet
      </Heading>

      <Paragraph>
        Tabeja suunnitellessa ja toteuttaessa on huomioitava, että Style
        Guidessa on kaksi samalta näyttävää, mutta semanttisesti toisistaan
        eroavaa välilehtikomponenttia. Ruudunlukija ilmoittaa semanttisen
        merkkauksen käyttäjälle, mikä helpottaa sivun hahmottamista.
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          <Code>
            <GatsbyLink page="Tabs" />
          </Code>{' '}
          tuottaa html-elementin <Code>nav</Code>, jonka semanttinen rooli on{' '}
          <Code>navigation</Code>. Rooli ilmaisee, että kyseessä on
          navigaatioelementti, ja linkin valinta johtaa uudelle sivulle.
          React-komponenttina ei suositeltu.
        </ListItem>
        <ListItem>
          <Code>TabList</Code> tuottaa elementin, jonka semanttinen rooli on{' '}
          <Code>role=tablist</Code>. Se tarkoittaa, että vaihdetaan välilehteä
          saman sivun sisällä. Suositeltava vaihtoehto useimmissa tapauksissa.
        </ListItem>
      </List>

      <Paragraph noMargin>
        Lue lisää{' '}
        <a href="../tablist/#saavutettavuus">
          TabList-sivun Saavutettavuus-osiosta
        </a>
        .
      </Paragraph>
    </Help>

    <Section title="Esimerkkejä">
      <Playground example={TabsExample} />
      <Playground example={TabsLongExample} />

      <SectionHeading>Toissijaiset välilehdet</SectionHeading>
      <Paragraph>
        Kun sivulla on jo ensisijaiset välilehdet, on mahdollista käyttää
        visuaalisesti erovia toissijaisia välilehtiä.
      </Paragraph>
      <Playground example={TabsSecondaryExample} />
      <Playground example={TabsSecondaryLongExample} />
      <Playground format="html">
        <>
          <h1 id="heading-verkkopalvelu-id">Lorem ipsum</h1>
          <nav
            aria-labelledby="heading-verkkopalvelu-id"
            className="tabs"
          >
            <ul className="tabs-list">
              <li className="tab">
                <a href="#lorem-ipsum">
                  <span>Lorem ipsum</span>
                </a>
              </li>
              <li className="tab active">
                <a
                  href="#dolor-sit-amet"
                  aria-current="page"
                >
                  <span>Dolor sit amet</span>
                </a>
              </li>
              <li className="tab">
                <a href="#consectetuer">
                  <span>Consectetuer</span>
                </a>
              </li>
            </ul>
          </nav>
        </>
      </Playground>

      <SectionHeading>Huomio-merkki</SectionHeading>
      <Paragraph>
        Jos välilehdellä on käyttäjälle jotain huomioitavaa, välilehdellä voi
        näyttää huomio-merkin. Muista lisätä ikonille <Code>ariaLabel</Code>
        -proppi, esim. teksti &quot;Huomioitavaa&quot;.
      </Paragraph>
      <Playground example={TabsItemStatusExample} />
    </Section>
    <Section title="Saavutettavuus">
      <Paragraph>
        <Code>Tabs</Code> on semanttisesti navigaatioelementti. Sillä on oltava
        saavutettava nimi, koska <Code>nav</Code> on landmark-tyyppinen
        elementti, johon käyttäjä pääsee hyppäämään myös suoraan. Linkitys
        tehdään <Code>ariaLabelledby</Code>-propilla <Code>Tabs</Code>ia
        edeltävään otsikkoon tai muuhun yhdistävään tekijään.
      </Paragraph>
      <Paragraph>
        <Code>Tabs</Code>ia ennen on aina käytettävä otsikkoa, joka kuvaa miten
        tabit liittyvät toisiinsa. Yleensä tämä on sivun pääotsikko (
        <Code>h1</Code>). <Code>Tabs</Code>ia ei koskaan käytetä ennen sivun
        pääotsikkoa (<Code>h1</Code>).
      </Paragraph>
      <Paragraph>
        Välilehden huomio-merkinnän tulee sisältää saavutettava teksti ikonille.
      </Paragraph>
    </Section>
  </Content>
)

export default Page
